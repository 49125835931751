<template>
<div></div>
</template>
<script>
export default {
    name: 'Logout',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth.token) {
            this.$router.push('/')
        }
    },
	data(){
		return{}
    },
	methods:{
		logout(){
            this.$storage.clear()
            this.$router.push('/');
        }
    },
    mounted(){
        this.logout();
    }
}
</script>